import { from } from 'rxjs';
import { filter, map, toArray } from 'rxjs/operators';
// import { tag } from "rxjs-spy/operators/tag";

import * as moviePred from './moviePredicates.js';
import * as offerPred from './offerPredicates.js';

/**
 * @param  {object[]} offers
 * @param  {object} filtersApplied
 * @return {object[]}
 */
export function filterOffers (offers, filtersApplied) {
  return offers
    .filter(offerPred.max_price(filtersApplied.max_price))
    .filter(offerPred.provider(filtersApplied.provider))
    .filter(offerPred.monetization_type(filtersApplied.monetization_type))
    .filter(offerPred.presentation_type(filtersApplied.presentation_type));
}

/**
 * @param  {object[]} movies
 * @param  {object} [filtersApplied={}] state.filters
 * @return {Rx.Observable} emitting movies passing all filters
 */
export function filterResults (movies, filtersApplied) {
  return from(movies)
    .pipe(
      // filter offers in offerGroup
      map(movie => ({
        ...movie,
        offersFiltered: filterOffers(movie.offers, filtersApplied),
      })),
      // filter out any movies with no offers left
      filter(movie => movie.offersFiltered.length > 0),
      filter(moviePred.hide_watched(filtersApplied.hide_watched)),
      filter(moviePred.show_bookmarked(filtersApplied.show_bookmarked)),
      filter(moviePred.show_liked(filtersApplied.show_liked)),
      filter(moviePred.max_runtime(filtersApplied.max_runtime)),
      filter(moviePred.min_year(filtersApplied.min_year)),
      filter(moviePred.genre(filtersApplied.genre)),
      filter(moviePred.director(filtersApplied.director)),
      filter(moviePred.dp(filtersApplied.dp)),
      filter(moviePred.cast(filtersApplied.cast)),
      toArray(),
    );
}

/**
 * @param  {object} filterOptions
 * @param  {object} filtersApplied
 * @return {object} of similar shape to input filtersApplied
 */
export function pruneFilters (filterOptions, filtersApplied) {
  let filters = {};

  Object.entries(filtersApplied).forEach(([ name, values ]) => {
    // we only need to prune filters that are list
    const prunedValues = Array.isArray(values)
      ? values.filter(v => filterOptions[name].includes(v))
      : values;

    // skip pruning providers unless it would result in no providers
    filters[name] = name === 'provider' && prunedValues.length
      ? values
      : prunedValues;
  });

  return filters;
}
