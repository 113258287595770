import { createSlice } from '@reduxjs/toolkit';

export const user = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    set: (state, { payload }) => payload,
    update: (state, { payload }) => ({ ...state, ...payload }),
  },
});
