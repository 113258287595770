import { from } from 'rxjs';
import { debounceTime, distinct, distinctUntilChanged, filter, ignoreElements, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { create } from "rxjs-spy";
// import { tag } from "rxjs-spy/operators/tag";

import { ACTION_TYPES, setFilters } from './actions.js';
import {
  filterOptions as filterOptionsSlice,
  filterCounts,
  list,
  locale,
  movies,
  providers,
  results,
  search,
  shortlist,
  sort as sortSlice,
  user,
  userAutoList,
  userFilms,
  userList,
  userLists,
} from './slices';
import { getFilters, getListSelected, getLocale, getMovies, getProviders, getShortlist, getUserAutoList, getUserList } from './selectors.js';

import { fetchMovies, fetchProviders } from '../api';
import { fetchFilms, fetchLists, streamAutoList, streamList, updateFilm } from '../api/user.js';
import { getFilterActionTypes } from './util.js';
import * as db from '../lib/db.js';
import { filterResults, pruneFilters } from '../lib/filters';
import { getFilterOptions } from '../lib/filterOptions';
import { updateSession } from '../lib/session';
import { sort } from '../lib/sort';
import { persistState } from '../lib/storage';
import filterCountWorker from '../worker/filterCount.client.js';

const ACTIONS_TO_TRIGGER_RESULTS = [
  ACTION_TYPES.SET_FILTERS,
  search.actions.set,
  ...getFilterActionTypes(),
];
const DEBOUNCE_DUR = 300;

const spy = create();
spy.log(/.*/);

let start;

const filtersEpic = (action$, state$) => action$.pipe(
  ofType(...ACTIONS_TO_TRIGGER_RESULTS),
  debounceTime(DEBOUNCE_DUR),
  withLatestFrom(state$),
  // don't continue if neither filter nor movies have changed
  distinctUntilChanged(([ , stateA ], [ , stateB ]) => (
    getFilters(stateA) === getFilters(stateB)
    && getMovies(stateA) === getMovies(stateB)
  )),
  // tap(() => start = Date.now()),
  switchMap(([ , state ]) => filterResults(getMovies(state), getFilters(state))),
  withLatestFrom(state$),
  map(([ results, state ]) => sort(state.sort, results, getFilters(state))),
  // tap(() => console.log('filters', Date.now() - start)),
  map(results.actions.set),
);

const filterCountsEpic = (action$, state$) => action$.pipe(
  ofType(results.actions.set),
  debounceTime(DEBOUNCE_DUR / 3),
  withLatestFrom(state$),
  distinct(([ , state ]) => state.results),
  tap(() => start = Date.now()),
  map(([ , state ]) => [ state.data.movies.map(m => m.id), getFilters(state), state.filterOptions ]),
  mergeMap(([ movie_ids, filters, filterOptions ]) => (
    filterCountWorker.postMessage({ movie_ids, filters, filterOptions })
  )),
  tap(() => console.log('filter counts', Date.now() - start)),
  map(filterCounts.actions.set),
);

const filterOptionsEpic = (action$, state$) => action$.pipe(
  ofType(movies.actions.set, providers.actions.set),
  debounceTime(0),
  // tap(() => start = Date.now()),
  withLatestFrom(state$),
  map(([ , state ]) => [
    getMovies(state),
    getProviders(state),
  ]),
  filter(([ movies, providers ]) => movies.length && providers.length),
  map(([ movies ]) => getFilterOptions(movies)),
  withLatestFrom(state$),
  // tap(() => console.log('filter options', Date.now() - start)),
  mergeMap(([ filterOptions, state ]) => [
    filterOptionsSlice.actions.set(filterOptions),
    setFilters(pruneFilters(filterOptions, getFilters(state))),
  ]),
);

const listEpic = (action$, state$) => action$.pipe(
  ofType(list.actions.set, locale.actions.set),
  debounceTime(0),
  withLatestFrom(state$),
  map(([ , state ]) => [
    getListSelected(state),
    getLocale(state),
  ]),
  distinctUntilChanged((a, b) => a.join('') === b.join('')),
  filter(([ list, locale ]) => !!list && !!locale),
  switchMap(([ list, locale ]) => fetchMovies(list, locale)),
  map(movies.actions.set),
);

const localeEpic = (action$, state$) => action$.pipe(
  ofType(locale.actions.set),
  debounceTime(0),
  distinctUntilChanged((a, b) => a.payload === b.payload),
  tap(action => updateSession({ locale: action.payload })),
  switchMap(({ payload }) => fetchProviders(payload)),
  map(providers.actions.set),
);

const moviesEpic = (action$) => action$.pipe(
  ofType(movies.actions.set),
  debounceTime(0),
  map(({ payload }) => payload),
  mergeMap(movies => db.bulkPut('movies', movies)),
  ignoreElements(),
);

const sortEpic = (action$, state$) => action$.pipe(
  ofType(sortSlice.actions.set),
  debounceTime(0),
  withLatestFrom(state$),
  map(([ , state ]) => sort(state.sort, state.results, state.filters)),
  map(results.actions.set),
);

const persistStateEpic = (action$, state$) => state$.pipe(
  debounceTime(5000),
  distinct(),
  tap(persistState),
  ignoreElements(),
);

const shortlistEpic = (action$, state$) => action$.pipe(
  ofType(shortlist.actions.remove),
  // only if we're on the shortlist page
  filter(() => window.location.pathname.match(/^\/shortlist\/?$/)),
  map(() => movies.actions.set(getShortlist(state$.value)))
);

const userEpic = (action$, state$) => action$.pipe(
  ofType(user.actions.set),
  switchMap(({ payload }) => Promise.all([
    // user logged in, fetch their saved films
    // user logged out, clear previously fetched films
    payload.username ? fetchFilms() : Promise.resolve([]),
    payload.username ? fetchLists() : Promise.resolve([]),
  ])),
  mergeMap(([ _userFilms, _userLists ]) => from([
    userFilms.actions.set(_userFilms),
    userLists.actions.set(_userLists),
  ])),
);

const userFilmEpic = (action$, state$) => action$.pipe(
  ofType(userFilms.actions.update),
  // maybe this is whacky, but this stream will dispatch an action of the type
  // it is subscribed to, but with this meta flag we can use for filtering it out
  filter(action => !action.meta.isFromEpic),
  debounceTime(60),
  mergeMap(({ payload }) => updateFilm(payload)),
  map(userFilm => userFilms.actions.update(userFilm, { isFromEpic: true })),
);

// NOTE: the entire purpose of this epic is to keep an indexedDB up-to-date
// for use within workers
const userFilmsEpic = (action$) => action$.pipe(
  ofType(userFilms.actions.set),
  debounceTime(0),
  map(({ payload }) => payload),
  // WARNING: the below will never delete userFilms from indexedDB...
  mergeMap(userFilms => db.bulkPut('userFilms', userFilms)),
  ignoreElements(),
);

const userListEpic = (action$, state$) => action$.pipe(
  ofType(userList.actions.set, locale.actions.set),
  debounceTime(0),
  withLatestFrom(state$),
  map(([ , state ]) => [
    getUserList(state),
    getLocale(state),
  ]),
  distinctUntilChanged((a, b) => a.join('') === b.join('')),
  filter(([ list, locale ]) => !!list && !!locale),
  switchMap(([ list, locale ]) => streamList(list, locale)),
  map(movies.actions.set),
);

const userAutoListEpic = (action$, state$) => action$.pipe(
  ofType(userAutoList.actions.set, locale.actions.set),
  debounceTime(0),
  withLatestFrom(state$),
  map(([ , state ]) => [
    getUserAutoList(state),
    getLocale(state),
  ]),
  distinctUntilChanged((a, b) => a.join('') === b.join('')),
  filter(([ list, locale ]) => !!list && !!locale),
  switchMap(([ list, locale ]) => streamAutoList(list, locale)),
  map(movies.actions.set),
);

export default combineEpics(
  filtersEpic,
  filterCountsEpic,
  filterOptionsEpic,
  listEpic,
  localeEpic,
  moviesEpic,
  persistStateEpic,
  shortlistEpic,
  sortEpic,
  userEpic,
  userAutoListEpic,
  userFilmEpic,
  userFilmsEpic,
  userListEpic,
);

// when any filter is updated or search set
  // results need to be recalculated
    // from movie collection
    // apply search filter
    // apply all filters
    // sort results
  // filter counts need to be recalculated
  // paging needs to reset (N/A)
// when sort is changed
  // sort results
