import dayjs from 'dayjs';

import { get, set } from './storage/index.js';

const KEY = 'shortlistExpiration';
const TTL_METHOD = 'beforeSunrise';
const TTL_METHODS = {
  beforeMidnight () {
    return dayjs().add(1, 'day').startOf('d');
  },
  beforeSunrise () {
    return dayjs().hour() >= 6
      ? dayjs().add(1, 'day').startOf('d').add(6, 'hour')
      : dayjs().startOf('d').add(6, 'hour');
  },
};

/**
 * @return {Promise}
 */
export function init () {
  return get(KEY)
    // do we need to expire our shortlist?
    .then(expirationTime => {
      if (expirationTime && isExpired(expirationTime)) {
        return get('storeState', {}).then(state => (
          set('storeState', Object.assign(state, { shortlist: [] }))
        ));
      }
    })
    // set expiration
    .then(setExpiration);
}


/**
 * @param {string} expirationTime ISO8601
 * @return {boolean}
 */
export function isExpired (expirationTime) {
  return dayjs().isAfter(dayjs(expirationTime));
}

export function setExpiration () {
  return set(KEY, TTL_METHODS[TTL_METHOD]().format());
}
