import * as api from '../api';

import uniq from '../../shared/util/uniq.js';

export default function search ({ locale, movies, query, results }) {
  function includes (movie) {
    return movie.title.toLowerCase().includes(query);
  }

  function startsWith (movie) {
    return movie.title.toLowerCase().startsWith(query);
  }

  return api.search(query, locale)
    .then(remoteResults => uniq([
      ...results.filter(startsWith),
      ...movies.filter(startsWith),
      ...results.filter(includes),
      ...movies.filter(includes),
      ...remoteResults,
    ], m => m.id).slice(0, 20));
}
