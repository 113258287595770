import * as selectors from './offerSelectors.js';

function reduce (selector) {
  return offers => Array.from(new Set(offers.map(selector)).values());
}

function reducePrice (selector) {
  return offers => offers.reduce((min, offer) => Math.min(min, selector(offer)), 999);
}

export const monetization_type = reduce(selectors.monetization_type);
export const presentation_type = reduce(selectors.presentation_type);
export const provider_id = reduce(selectors.provider_id);
export const minPrice = reducePrice(selectors.price);
