export default class WorkerPool {
  i = 0;
  workers = [];

  /**
   * @param  {string} src
   * @param  {object} options
   * @param  {number} [size = 1]
   */
  constructor (src, options = {}, size = 1) {
    for (let i = 0; i < size; i++) {
      this.workers.push(new Worker(src, options));
    }
    this.workers.forEach(w => w.onerror = console.error);
  }

  /**
   * @param  {any} data
   * @param  {number} [i = this.i]
   * @return {Promise} resolves with worker response
   */
  postMessage (data, i) {
    const worker = this.workers[i != null ? i : this.i];
    this.i = this.i < this.workers.length - 1 ? ++this.i : 0;

    return new Promise((res, rej) => {
      worker.onmessage = res;
      worker.postMessage(data);
    }).then(({ data }) => data);
  }
}
