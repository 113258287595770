import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShortlist, getUser } from '../store/selectors.js';
import { shortlist as shortlistSlice, userFilms } from '../store/slices';

export default function MovieActions ({ movie }) {
  const dispatch = useDispatch();
  const shortlist = useSelector(getShortlist);
  const user = useSelector(getUser);

  const isShortlisted = React.useMemo(() => (
    !!shortlist.find(m => m.id === movie.id)
  ), [ movie, shortlist ]);

  const requireLoggedIn = React.useCallback(() => {
    if (!user.username) alert('Please login or create an account to bookmark films');

    return !!user.username;
  }, [ user ]);

  const handleClickBookmark = React.useCallback(e => {
    if (!requireLoggedIn()) return;
    dispatch(userFilms.actions.update({ tmdb_id: movie.id, ...movie.user, bookmarked: !movie.user.bookmarked }));
  }, [ dispatch, movie.id, movie.user, requireLoggedIn ]);

  const handleClickLike = React.useCallback(e => {
    if (!requireLoggedIn()) return;
    dispatch(userFilms.actions.update({
      tmdb_id: movie.id,
      ...movie.user,
      liked: !movie.user.liked,
      // set watched if we're setting to liked
      watched: !movie.user.liked || movie.user.watched,
    }));
  }, [ dispatch, movie.id, movie.user, requireLoggedIn ]);

  const handleClickShortlist = React.useCallback(() => {
    dispatch(isShortlisted
      ? shortlistSlice.actions.remove(movie)
      : shortlistSlice.actions.add(movie)
    );
  }, [ dispatch, isShortlisted, movie ]);

  const handleClickWatched = React.useCallback(e => {
    if (!requireLoggedIn()) return;
    dispatch(userFilms.actions.update({ tmdb_id: movie.id, ...movie.user, watched: !movie.user.watched }));
  }, [ dispatch, movie.id, movie.user, requireLoggedIn ]);

  return (
    <div className="movie-actions">
      <button onClick={handleClickWatched} type="button">
        {movie.user.watched ? 'Mark film unwatched' : 'Mark film watched'}
      </button>
      <button onClick={handleClickBookmark} type="button">
        {movie.user.bookmarked ? 'Unbookmark film' : 'Bookmark film'}
      </button>
      <button onClick={handleClickLike} type="button">
        {movie.user.liked ? 'Unlike film' : 'Like film'}
      </button>
      <button onClick={handleClickShortlist} type="button">
        {isShortlisted ? 'un-shortlist' : 'shortlist' }
      </button>
    </div>
  )
}
