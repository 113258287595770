import { get, set } from '../storage';

const KEY = 'session';

export function getSession () {
  return get(KEY, {});
}

export function updateSession (sessionAttr) {
  return getSession()
    .then(session => set(KEY, { ...session, ...sessionAttr }));
}
