import curry from '../../../shared/util/curry.js';

const REGEX = /^(\w+)\.(\w+)$/;

export const genActionName = curry((namespace, verb) => {
  return `${namespace}.${verb}`;
});

export function list (namespace) {
  return [ 'add', 'remove', 'toggle' ].map(genActionName(namespace));
}

export function reverseActionType (actionType) {
  const matches = actionType.match(REGEX);

  try {
    return { namespace: matches[1], verb: matches[2] };
  }
  catch (e) {
    throw new Error('received an actionType with unexpected format');
  }
}

export function set (namespace) {
  return genActionName(namespace, 'set');
}
