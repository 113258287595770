import * as movieSelectors from '../movieSelectors.js';
import * as offerReducers from '../offerReducers.js';

import falsy from './util/falsy.js';
import gte from './util/gte.js';
import listsIntersect from './util/listsIntersect.js';
import lte from './util/lte.js';
import truthy from './util/truthy.js';

/**
 * @param  {function} fn
 * @return {function} function that will supply the value of input entry to fn argument
 */
function fromMovie (fn) {
  return movie => fn(movie.offers);
}

// movie filtering
export const cast = listsIntersect(movieSelectors.cast);
export const director = listsIntersect(movieSelectors.director);
export const dp = listsIntersect(movieSelectors.dp);
export const genre = listsIntersect(movieSelectors.genre);
export const hide_watched = falsy(movieSelectors.watched);
export const max_runtime = lte(movieSelectors.max_runtime);
export const min_year = gte(movieSelectors.min_year);
export const show_bookmarked = truthy(movieSelectors.bookmarked);
export const show_liked = truthy(movieSelectors.liked);


// offer filtering
// TODO: consider removing these
export const monetization_type = listsIntersect(fromMovie(offerReducers.monetization_type));
export const presentation_type = listsIntersect(fromMovie(offerReducers.presentation_type));
export const provider = listsIntersect(fromMovie(offerReducers.provider_id));
export const max_price = lte(fromMovie(offerReducers.minPrice));
