import React from 'react';
import { Errors, Form, SubmitButton, validator } from '@curiouser/react-forms';
import { Fields, TextField, PasswordField } from '@curiouser/react-forms';

import { login } from '../api/user.js';

const validations = [{
  names: [ 'identifier', 'password' ],
  tests: [[ validator.tests.required, validator.messages.required ]],
}];

/**
 * @property {React.ref} forwardedRef
 */
export default function LoginForm ({ onSubmit }) {
  const form = React.useRef();
  const [ errors, setErrors ] = React.useState([]);
  const [ isLoading, setIsLoading ] = React.useState(false);

  const handleSubmit = React.useCallback(e => {
    e.preventDefault();

    if (isLoading || !form.current.validate()) return;
    setIsLoading(true);

    return login(form.current.getData())
      .then(onSubmit)
      .catch(setErrors)
      .then(() => setIsLoading(false));
  }, [ isLoading, onSubmit ]);

  return (
    <form className="user__login user__form login" onSubmit={handleSubmit}>
      <Form errors={errors} formName="login" ref={form} validateAsYouGo={false} validations={validations}>
        <Errors />
        <Fields>
          <TextField inputMode="email" label="Username/Email" name="identifier" />
          <PasswordField label="Password" name="password" />
        </Fields>
        <SubmitButton isLoading={isLoading}>Login</SubmitButton>
      </Form>
    </form>
  );
}
