import React from 'react';
import { useDispatch } from 'react-redux';

import { set } from '../store/lib/actionTypeGenerators.js';

import isNumber from '../util/isNumber.js';

export default function TextInput ({ name }) {
  const dispatch = useDispatch();

  function onChange (e) {
    const data = isNumber(e.target.value)
      ? Number(e.target.value)
      : e.target.value;

    dispatch({ data, type: set(name) });
  }

  return (
    <div>
      <label>{name}</label>
      <input name={name} onChange={onChange} type="number" />
    </div>
  );
}
