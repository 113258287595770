import { fromEvent } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DropdownWrapper from '@curiouser/react-forms/dist/components/fields/components/DropdownWrapper.jsx';

import { getLocale, getMovies, getResults } from '../store/selectors.js';
import { movie as movieSlice } from '../store/slices';

import search from '../util/search.js';

const QUERY_DEBOUNCE_WINDOW = 500;
const QUERY_MIN_LENGTH = 3;

export default function Search () {
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = React.useRef();
  const locale = useSelector(getLocale, shallowEqual);
  const movies = useSelector(getMovies, shallowEqual);
  const results = useSelector(getResults, shallowEqual);

  const [ hasFocus, setHasFocus ] = React.useState(false);
  const [ isOpen, setIsOpen ] = React.useState(false);
  const [ options, setOptions ] = React.useState([]);
  const [ query, setQuery ] = React.useState('');

  const handleBlur = React.useCallback(() => setHasFocus(false), []);
  const handleFocus = React.useCallback(() => setHasFocus(true), []);

  const select = React.useCallback(movie => {
    dispatch(movieSlice.actions.set(movie));
    history.push(`/movie/${movie.id}`);
    setIsOpen(false);
  }, [ dispatch, history ]);

  React.useEffect(() => {
    // if (!isOpen && hasFocus && query.length >= QUERY_MIN_LENGTH) setIsOpen(true);
    if (isOpen && query.length < QUERY_MIN_LENGTH) setIsOpen(false);
  }, [ isOpen, query ]);

  React.useEffect(() => {
    if (hasFocus) setIsOpen(true);
  }, [ hasFocus ]);

  React.useEffect(() => {
    const change$ = fromEvent(inputRef.current, 'input')
      .pipe(
        map(e => e.target.value.toLowerCase()),
        // always update input value immediately
        tap(setQuery),
        debounceTime(QUERY_DEBOUNCE_WINDOW),
        filter(query => query.length >= QUERY_MIN_LENGTH),
        switchMap(query => search({ locale, query, movies, results })),
        // open our dropdown as we receive new results
        tap(() => setIsOpen(true)),
        map(results => results.map(r => ({ label: `${r.title} (${r.year})`, value: r }))),
      ).subscribe(setOptions);

    return () => change$.unsubscribe();
  }, [ locale, movies, results ]);

  return (
    <DropdownWrapper
      className="search"
      focusOnOpen={false}
      isOpen={isOpen}
      onSelect={select}
      optionKeySelector={optionKeySelector}
      options={options}
      setIsOpen={setIsOpen}>
      <input
        onBlur={handleBlur}
        onChange={noop}
        onFocus={handleFocus}
        placeholder="Search by title..."
        ref={inputRef}
        type="text"
        value={query} />
    </DropdownWrapper>
  )
}

function noop () {}

function optionKeySelector (option) {
  return option.value.id;
}
