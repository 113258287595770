import curry from '../../../../shared/util/curry.js';

/**
 * @param  {array}  [valuesIncluded=[]]
 * @param  {function} selector accepts same input as parent function
 * @return {function} accepts movie or offers collection, returns boolean
 */
export default curry((selector, valuesIncluded) => {
  return item => !valuesIncluded.length || valuesIncluded.some(v => selector(item) === v);
});
