import React from 'react';
import { useSelector } from 'react-redux';

import { getLocale } from '../store/selectors.js';

import booleanify from '../lib/transformers/booleanify.js';
import justwatchLocaleToBrowserLanguage from '../util/justwatchLocaleToBrowserLanguage.js';
import jwIconUrl from '../util/jwIconUrl.js';

/**
 * @param  {object} offer
 * @param  {string} locale - from justwatch locale.full_name
 * @return {string} $4.99 or 49kr
 */
function renderPrice (offer, locale) {
  const browserLocale = justwatchLocaleToBrowserLanguage(locale);

  return offer.retail_price.toLocaleString(browserLocale, {
    currency: offer.currency || 'USD', // currency is missing sometimes?!?
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
  });
}

/**
 * @param       {object} movie
 * @param       {object} providers
 * @return      {jsx} .movie-offers
 */
export default function MovieOffers ({ movie, providers }) {
  const locale = useSelector(getLocale);
  const [ showAllOffers, setShowAllOffers ] = React.useState(false);

  const label = showAllOffers ? 'Showing all offers' : 'Showing filtered offers';
  const offers = showAllOffers ? movie.offers : movie.offersFiltered;
  const toggleCTA = showAllOffers ? 'See filtered offers' : 'See all offers';

  const handleClick = React.useCallback(e => {
    setShowAllOffers(booleanify(e.currentTarget.value));
  }, []);

  return (
    <div className="movie__offers movie-offers">
      <div>
        {label}.
        <button className="btn btn--link" onClick={handleClick} type="button" value={!showAllOffers}>&nbsp;{toggleCTA}</button>
      </div>
      <ul className="movie-offers__offers">
        {offers
          // NOTE: it happens that an offer provider isn't in our providers collection
          .filter(o => providers[o.provider_id])
          .map(o => {
            const id = o.provider_id + o.presentation_type + o.monetization_type;
            // only use the deeplink for VUDU for now
            const url = [ '7', '9' ].includes(o.provider_id) && o.urls.deeplink_android_tv
              ? o.urls.deeplink_android_tv.replace('com.amazon.amazonvideo.livingroom', 'com.amazon.avod.thirdpartyclient')
              : o.urls.standard_web;
            const provider = providers[o.provider_id];

            return (
              <li className="offer" key={id}>
                <a href={url}>
                  <span className="offer__price">{`${renderPrice(o, locale)} to ${o.monetization_type}`}</span>
                  <span className="offer__res">{o.presentation_type}</span>
                  <img alt={`Watch ${movie.title} on ${provider.name}`} className="offer__icon" src={jwIconUrl(provider.icon_url)} />
                </a>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
}
