import { filters } from '../../constants.js';
import * as selectors from '../movieSelectors.js';

const SORT_MAP = {
  provider: (a, b) => Number(a) > Number(b) ? 1 : -1,
};

/**
 * @param  {object} movies
 * @return {array[]} entries
 */
export function getFilterOptions (movies) {
  return Object.fromEntries(
    filters
      .filter(([ name, defaultValue ]) => Array.isArray(defaultValue))
      .map(([ name, defaultValue ]) => [
        name,
        getFilterOptionSet(name, movies),
      ])
  );
}

function getFilterOptionSet (name, movies) {
  const optionSet = Object.values(movies)
    .reduce((set, m) => new Set([ ...set, ...selectors[name](m) ]), new Set());

  return Array.from(optionSet).sort(SORT_MAP[name]);
}
