import React from 'react';
import { BrowserRouter, Link, Switch, Route } from "react-router-dom";
import './App.css';
import '@curiouser/react-forms/dist/index.css';
import './styles/btn.css';
import './styles/form.css';

import DetailsView from './views/DetailsView.jsx';
import HomeView from './views/HomeView.jsx';
import ListView from './views/ListView.jsx';
import ShortlistView from './views/ShortlistView.jsx';
import UserAutoListView from './views/UserAutoListView.jsx';
import UserListView from './views/UserListView.jsx';

import ListSelector from './components/ListSelector.jsx';
import LocaleSelector from './components/LocaleSelector.jsx';
import ScrollToTop from './components/ScrollToTop.jsx';
import Search from './components/Search.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <h3>
          <Link to="/">WATCHLIST</Link>
        </h3>
        <Search />
        <LocaleSelector />
        <ListSelector />
        <Switch>
          <Route path="/movie/:id">
            <DetailsView />
          </Route>
          <Route path="/shortlist/">
            <ShortlistView />
          </Route>
          <Route path="/user/list/:slug">
            <UserListView />
          </Route>
          <Route path="/user/autolist/:slug">
            <UserAutoListView />
          </Route>
          <Route path="/list/:slug">
            <ListView />
          </Route>
          <Route path="/">
            <HomeView />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
