import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShortlist } from '../store/selectors.js';
import { movies } from '../store/slices/index.js';

import Filters from '../components/Filters.jsx';
import Results from '../components/Results.jsx';
import Sort from '../components/Sort.jsx';

export default function ListView () {
  const dispatch = useDispatch();
  const shortlist = useSelector(getShortlist);

  React.useEffect(() => {
    dispatch(movies.actions.set(shortlist));
  }, []); // eslint-disable-line 

  return (
    <div className="view view--list">
      <Filters />
      <Sort />
      <Results />
    </div>
  )
}
