import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { userList } from '../store/slices';
import { getUserList, getUserLists } from '../store/selectors.js';

export default function UserListSelector () {
  const dispatch = useDispatch();
  const history = useHistory();
  const userListSelected = useSelector(getUserList, shallowEqual);
  const userLists = useSelector(getUserLists, shallowEqual);

  function handleChange (e) {
    const slug = e.target.value;
    dispatch(userList.actions.set(slug));
    history.push(`/user/list/${slug}`);
  }

  return userLists.length ? (
    <select onChange={handleChange} value={userListSelected}>
      <option value="" disabled>Select a letterboxd list</option>
      {userLists.map(({ name, slug }) => (
        <option key={slug} value={slug}>{name}</option>
      ))}
    </select>
  ) : null;
}
