import { set } from '../lib/sliceGenerators.js';

import { getDefaultFilterOptions } from '../util.js';

export { results } from './results.js';
export { shortlist } from './shortlist.js';
export { user } from './user.js';
export { userFilms } from './userFilms.js';

export const filterOptions = set('filterOptions', getDefaultFilterOptions());
// export const filters = set('filters', )
export const filterCounts = set('filterCounts', {});
export const list = set('list', '');
export const lists = set('lists', []);
export const locale = set('locale', '');
export const locales = set('locales', []);
export const movie = set('movie', null);
export const movies = set('movies', []);
export const providers = set('providers', []);
export const search = set('search', '');
export const sort = set('sort', '');
export const userAutoList = set('userAutoList', '');
export const userList = set('userList', '');
export const userLists = set('userLists', []);
