import curry from '../../../../shared/util/curry.js';

/**
 * @param  {function} selector accepts same input as parent function
 * @param  {number} val
 * @return {function} accepts movie of offers collection, returns boolean
 */
export default curry((selector, val) => {
  return item => !val || selector(item) >= val;
});
