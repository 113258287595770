import React from 'react';
import { useDispatch } from 'react-redux';

import { set } from '../store/lib/actionTypeGenerators.js';

export default function Checkbox ({ count, name }) {
  const dispatch = useDispatch();

  function onChange (e) {
    const data = e.target.checked;

    dispatch({ data, type: set(name) });
  }

  return (
    <div>
      <label>
        {`${name} (${count})`}
        <input name={name} onChange={onChange} type="checkbox" />
      </label>
    </div>
  );
}
