import { exists } from 'functional.js';

function sortOnKey (key) {
  return (a, b) => a[key] > b[key] ? 1 : -1;
}

function reverseSortOnKey (key) {
  return (a, b) => a[key] < b[key] ? 1 : -1;
}

function getLowPrice (lowPrice, { retail_price = 0 }) {
  return exists(lowPrice)
    ? Math.min(lowPrice, retail_price)
    : retail_price;
}

export function sort (sort, results, filters) {
  if (!sort) return results;
  let resultsClone = results.slice();

  function pricePredicate ({ monetization_type, presentation_type, provider_id }) {
    return (!filters.provider.length || filters.provider.includes(provider_id))
      && (!filters.monetization_type.length || filters.monetization_type.includes(monetization_type))
      && (!filters.presentation_type.length || filters.presentation_type.includes(presentation_type));
  }

  switch (sort) {
    case 'Oldest':
      return resultsClone.sort(sortOnKey('year'));
    case 'Newest':
      return resultsClone.sort(reverseSortOnKey('year'));
    case 'Price':
      return resultsClone.sort((a, b) => {
        const aLow = a.offers.filter(pricePredicate).reduce(getLowPrice, null);
        const bLow = b.offers.filter(pricePredicate).reduce(getLowPrice, null);

        return aLow > bLow ? 1 : -1;
      });
    case 'Rating':
      return resultsClone.sort(reverseSortOnKey('rating'));
    case 'Shortest TRT':
      return resultsClone.sort(sortOnKey('runtime'));
    case 'Longest TRT':
      return resultsClone.sort(reverseSortOnKey('runtime'));
    case 'Alpha':
      return resultsClone.sort(sortOnKey('title'));
    default:
      return results;
  }
}
