import React from 'react';
import { useSelector } from 'react-redux';

import Result from './Result.jsx';

import { getFilters, getMovies } from '../store/selectors.js';
import { getDefaultFilters } from '../store/util.js';

import uniq from '../../shared/util/uniq.js';
import { filterResults } from '../lib/filters';

const FREE_MONETIZATIONS = [ 'ads', 'flatrate', 'free' ];
const ALERT_MIN = 4;

export default function SubscriptionSuggestion ({ name, provider_id }) {
  const [ results, setResults ] = React.useState([]);

  const filters = useSelector(getFilters);
  const movies = useSelector(getMovies);

  React.useEffect(() => {
    const provider = uniq(filters.provider.concat(provider_id));
    const subscriptionFilters = {
      ...getDefaultFilters(),
      monetization_type: FREE_MONETIZATIONS,
      provider,
    }

    filterResults(movies, subscriptionFilters).subscribe(filteredMovies => {
      setResults(filteredMovies
        // filter to only movies with offers left
        .filter(({ offers }) => offers.some(o => o.provider_id === provider_id))
      );
    });
  }, [ movies, filters.provider, provider_id ]);

  return results.length >= ALERT_MIN
    ? (
      <div className="subscription-suggestion">
        {`Time to subscripe to ${name}? We found ${results.length} films not available through your existing subscriptions, but available through ${name}`}
        <ul className="results results-list">
          {results.map(r => <Result key={r.id} movie={r} />)}
        </ul>
      </div>
    ) : null;
}
