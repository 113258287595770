import { openDB } from 'idb';
export let db;

async function init () {
  // await deleteDB('watchlist');
  db = await openDB('watchlist', 1, {
    upgrade (db, oldVersion, newVersion, transaction) {
      db.createObjectStore('movies', { keyPath: 'id' });
      db.createObjectStore('userFilms', { keyPath: 'id' });
    },
  });
  // global.db = db;
}

/**
 * @param  {string} table
 * @return {Promise} resolves to collection
 */
export async function all (table) {
  return await db.getAll(table);
}

/**
 * @param  {string} table
 * @param  {object[]} data
 * @return {Promise}
 */
export async function bulkPut (table, data) {
  const tx = db.transaction(table, 'readwrite');

  data.forEach(item => tx.store.put(item));

  return await tx.done;
}

/**
 * @param  {string} table
 * @return {Promise}
 */
export async function clear (table) {
  return await db.clear(table);
}

/**
 * seems to be slower than loading entire table and filtering in js
 * @param  {string} table
 * @param  {function} pred
 * @return {Promise} object[]
 */
export async function filter (table, pred) {
  let c = await db.transaction(table).store.openCursor();
  let matches = [];

  while (c) {
    if (pred(c.value)) matches.push(c.value);
    c = await c.continue();
  }

  return matches;
}

init();
