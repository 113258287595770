import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { locale as localeSlice } from '../store/slices';
import { getLocaleOptions, getLocale } from '../store/selectors.js';

export default function LocaleSelector () {
  const dispatch = useDispatch();
  const localeOptions = useSelector(getLocaleOptions, shallowEqual);
  const locale = useSelector(getLocale, shallowEqual);

  function handleChange (e) {
    const slug = e.target.value;
    dispatch(localeSlice.actions.set(slug));
  }

  return (
    <select onChange={handleChange} value={locale}>
      <option value="" disabled>Select your location</option>
      {localeOptions.map(locale => (
        <option key={locale.full_locale} value={locale.full_locale}>{locale.country}</option>
      ))}
    </select>
  );
}
