import * as offerReducers from './offerReducers.js';

function selectName ([ name, id ]) {
  return name;
}

/**
 * @param  {object} movie
 * @return {string|number}
 */
export const bookmarked = movie => movie.user.bookmarked;
export const cast = movie => movie.cast.map(selectName);
export const director = movie => movie.directors.map(selectName);
export const dp = movie => movie.dps.map(selectName);
export const genre = movie => movie.genres;
export const liked = movie => movie.user.liked;
export const max_runtime = movie => Number(movie.runtime);
export const min_year = movie => Number(movie.year);
export const watched = movie => movie.user.watched;

/**
 * @param  {object} movie
 * @return {array}
 */
export const monetization_type = movie => offerReducers.monetization_type(movie.offers);
export const presentation_type = movie => offerReducers.presentation_type(movie.offers);
export const provider = movie => offerReducers.provider_id(movie.offers);
export const max_price = movie => offerReducers.minPrice(movie.offers);
