import { combineReducers } from 'redux';
import { ACTION_TYPES } from './actions.js';
import {
  filterOptions,
  filterCounts,
  list,
  lists,
  locale,
  locales,
  movie,
  movies,
  providers,
  results,
  search,
  shortlist,
  sort,
  user,
  userAutoList,
  userFilms,
  userList,
  userLists,
} from './slices';

import { reverseActionType } from './lib/actionTypeGenerators.js';
import { getDefaultFilters, getFilterActionTypes } from './util.js';

function filters (state = getDefaultFilters(), { data, type }) {
  // handle individual filter group overwrites
  if (getFilterActionTypes().includes(type)) {
    const { namespace, verb } = reverseActionType(type);
    let val = state[namespace];

    if (verb === 'set') val = data;

    return { ...state, [namespace]: val };
  }

  // handle hard coded ACTION_TYPES
  switch (type) {
    case ACTION_TYPES.SET_FILTERS:
      return data;
    default:
      return state;
  }
}

export default combineReducers({
  data: combineReducers({
    lists: lists.reducer,
    locales: locales.reducer,
    movies: movies.reducer,
    providers: providers.reducer,
  }),
  filterOptions: filterOptions.reducer,
  filters,
  filterCounts: filterCounts.reducer,
  list: list.reducer,
  locale: locale.reducer,
  movie: movie.reducer,
  results: results.reducer,
  search: search.reducer,
  shortlist: shortlist.reducer,
  sort: sort.reducer,
  user: user.reducer,
  userAutoList: userAutoList.reducer,
  userFilms: userFilms.reducer,
  userList: userList.reducer,
  userLists: userLists.reducer,
});
