import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createEpicMiddleware } from 'redux-observable';

import epic from './epics.js';
import reducer from './reducer.js';

const composeEnhancers = composeWithDevTools({
  name: 'WatchList', actionsBlacklist: ['REDUX_STORAGE_SAVE']
});
const epicMiddleware = createEpicMiddleware();

export default function makeStore (initialState = {}) {
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        epicMiddleware,
      ),
    ),
  );

  epicMiddleware.run(epic);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducer.js', () => {
        store.replaceReducer(reducer);
      });
    }
  }

  return store;
}
