import React from 'react';

import LoginForm from './LoginForm.jsx';
import RegisterForm from './RegisterForm.jsx';

import { logout } from '../api/user.js';

const DEFAULT_MODE = 'login';

/**
 * @property {function} setUser
 * @property {object} user
 */
export default function UserForm ({ setUser, user }) {
  const [ mode, setMode ] = React.useState(user.username ? 'active' : DEFAULT_MODE); // login, register or active

  const Form = React.useMemo(() => {
    switch (mode) {
      case 'login':
        return LoginForm;
      case 'register':
        return RegisterForm;
      default:
        return () => null;
    }
  }, [ mode ]);

  const handleClickLogout = React.useCallback(() => {
    logout()
      .then(() => setUser({}))
      .then(() => setMode(DEFAULT_MODE));
  }, [ setUser ]);

  const handleClickToggle = React.useCallback(e => {
    setMode(e.target.value);
  }, []);

  const handleSubmit = React.useCallback(user => {
    setUser(user);
    setMode('active');
  }, [ setUser ]);

  const renderToggle = React.useCallback(() => {
    switch (mode) {
      case 'login':
        return (
          <button onClick={handleClickToggle} type="button" value="register">
            Register for an account
          </button>
        );
      case 'register':
        return (
          <button onClick={handleClickToggle} type="button" value="login">
            Already have an account? Login
          </button>
        );
      default:
        return null;
    }
  }, [ handleClickToggle, mode ]);

  return (
    <div className="user">
      <Form onSubmit={handleSubmit} />
      {renderToggle()}
      {mode === 'active' && (
        <div className="user__profile">
          {JSON.stringify(user)}
          <button onClick={handleClickLogout} type="button">Log out</button>
        </div>
      )}
    </div>
  );
}
