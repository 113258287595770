import React from 'react';
import { useSelector } from 'react-redux';

import SubscriptionSuggestion from './SubscriptionSuggestion.jsx';

import { getFilters, getProviders } from '../store/selectors.js';

export default function SubscriptionSuggestions () {
  const filters = useSelector(getFilters);
  const providers = useSelector(getProviders);
  const subscriptionProviders = React.useMemo(() =>
    providers
      // provider is not in users current filter set and provider offers subscription (flatrate)
      .filter(p => !filters.provider.includes(p.id) && p.monetization_types.includes('flatrate')),
  [ filters.provider, providers ]);
  


  return subscriptionProviders.map(p => (
    <SubscriptionSuggestion key={p.id} name={p.clear_name} provider_id={p.id} />
  ));
}
