import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * Router utility component to scroll to top on each route change
 * NOTE: only acts on "PUSH" state and when the path has changed
 * @param       {object} history
 * @param       {jsx} children
 * @credit https://stackoverflow.com/a/54343182/564699
 */
function ScrollToTop({ history, children }) {
  const pathRef = React.useRef(history.location.pathname);

  useEffect(() => history.listen(() => {
    const path = history.location.pathname;

    // path must be changed and action must be PUSH
    if (pathRef.current !== path && history.action === 'PUSH') {
      pathRef.current = path;
      window.scrollTo(0, 0);
    }
  }), [ history ]);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
