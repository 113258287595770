import { connect } from 'react-redux';
import { user } from '../store/slices';
import UserForm from '../components/UserForm.jsx';

function mapStateToProps (state) {
  return { user: state.user };
}

function mapDispatchToProps (dispatch) {
  return {
    setUser: userData => dispatch(user.actions.set(userData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
