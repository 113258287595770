/** @module */
import { createSlice } from '@reduxjs/toolkit';

const prepare = (payload, meta = {}) => ({ meta, payload });
const reducer = (state, { payload }) => payload;

/**
 * a simple reducer generator for common replacement of store data
 * @function set
 * @param {string} name
 * @param {any} initialState
 * @return {object} for supplying to redux-toolkit.createSlice
 */
export function set (name, initialState) {
  return createSlice({
    name,
    initialState,
    reducers: {
      set: { prepare, reducer },
    },
  });
}
