import { createSelector } from '@reduxjs/toolkit';
import { filterOffers } from '../lib/filters';

export const getFilters = state => state.filters;
export const getFilterCounts = state => state.filterCounts;
export const getFilterOptions = state => state.filterOptions;
export const getFilterValues = (state, filterName) => getFilters(state)[filterName];
export const getLocaleOptions = state => state.data.locales;
export const getLocale = state => state.locale;
export const getListOptions = state => state.data.lists;
export const getListSelected = state => state.list;
export const getMovie = createSelector(
  state => state.movie,
  getFilters,
  getUserFilms,
  (movie, filters, userFilms) => movie && ({
    ...movie,
    offersFiltered: filterOffers(movie.offers, filters),
    user: userFilms[movie.tmdb_id] || {},
  })
);
export const getMovies = createSelector(
  state => state.data.movies,
  getUserFilms,
  (movies, userFilms) => movies.map(m => {
    const userFilm = userFilms[m.tmdb_id] || {};

    return { ...m, user: userFilm };
  }),
);
export const getOffers = state => state.data.offers;
export const getProviders = state => state.data.providers.filter(p => Array.isArray(p.monetization_types));
export const getResults = state => state.results;
export const getShortlist = state => state.shortlist;
export const getSort = state => state.sort;
export const getUser = state => state.user;
// NOTE: for scope hoisting
export function getUserFilms (state) {
  return state.userFilms;
}
export const getUserAutoList = state => state.userAutoList;
export const getUserList = state => state.userList;
export const getUserLists = state => state.userLists;
