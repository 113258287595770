import React from 'react';

import MovieDetails from '../components/MovieDetails.jsx';

export default function DetailsView () {
  return (
    <div className="view view--details">
      <MovieDetails />
    </div>
  )
}
