import React from 'react';
import { Errors, Form, SubmitButton, validator } from '@curiouser/react-forms';
import { Fields, TextField, PasswordField } from '@curiouser/react-forms';

import { register } from '../api/user.js';

const validations = [
  {
    names: [ 'email', 'password', 'username' ],
    tests: [[ validator.tests.required, validator.messages.required ]],
  }, {
    names: [ 'email' ],
    tests: [[ validator.tests.email, validator.messages.email ]],
  },
];

/**
 * @property {React.ref} forwardedRef
 */
export default function LoginForm ({ onSubmit }) {
  const form = React.useRef();
  const [ errors, setErrors ] = React.useState([]);
  const [ isLoading, setIsLoading ] = React.useState(false);

  const handleSubmit = React.useCallback(e => {
    e.preventDefault();

    if (isLoading || !form.current.validate()) return;
    setIsLoading(true);

    return register(form.current.getData())
      .then(onSubmit)
      .catch(setErrors)
      .then(() => setIsLoading(false));
  }, [ isLoading, onSubmit ]);

  return (
    <form className="user__register register" onSubmit={handleSubmit}>
      <Form errors={errors} formName="register" ref={form} validateAsYouGo={false} validations={validations}>
        <Errors />
        <Fields>
          <TextField label="Username" name="username" />
          <TextField inputMode="email" label="Email" name="email" />
        </Fields>
        <Fields>
          <PasswordField label="Password" name="password" />
          <TextField label="Letterboxd Username (optional)" name="letterboxd_username" />
        </Fields>
        <SubmitButton isLoading={isLoading}>Register</SubmitButton>
      </Form>
    </form>
  );
}
