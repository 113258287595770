import { fromFetch } from 'rxjs/fetch';
import { map, mergeMap } from 'rxjs/operators';
// import { tag } from "rxjs-spy/operators/tag";

import streamMovieList from './util/streamMovieList.js';

const fetchApi = (...args) => fetch(...args).then(res => res.json());

export function fetchLetterboxdSlug ({ title, year }) {
  return fromFetch(`/api/letterboxd-slug/?title=${encodeURIComponent(title)}&year=${year}`)
    .pipe(
      mergeMap(res => res.json()),
      map(({ slug }) => slug),
    );
}

export function fetchLists () {
  return fetchApi('/api/list/')
    .then(lists => lists.sort((a, b) => a.name > b.name ? 1 : -1))
    // .then(lists => Promise.all(lists.map(l => fetchMovies(l.slug, 'en-US', 6).toPromise().then(movies => Object.assign(l, { movies })))));
}

export function fetchLocales () {
  return fetchApi('/api/locale/');
}

/**
 * @param  {string} list
 * @param  {string} locale
 * @return {Observable} emitting entire response collection of movies
 */
export function fetchMovies (list, locale, limit = Infinity) {
  return streamMovieList(`/api/list/${list}`, {
    headers: { 'x-locale': locale },
  }, limit);
}

export function fetchMovieDetails (id, locale) {
  return fetchApi(`/api/movie/${id}`, {
    headers: { 'x-locale': locale },
  });
}

export function fetchProviders (locale) {
  return fetchApi('/api/providers/', {
    headers: { 'x-locale': locale },
  });
}

export function search (query, locale) {
  return fetchApi(`/api/search/movie/?title=${encodeURIComponent(query)}`, {
    headers: { 'x-locale': locale },
  });
}
