import MultiSelect from './components/MultiSelect.jsx';
import TextInput from './components/TextInput.jsx';
import Checkbox from './components/Checkbox.jsx';

export const filters = [
  [ 'provider', [], MultiSelect ],
  [ 'monetization_type', [], MultiSelect ],
  [ 'genre', [], MultiSelect ],
  [ 'max_price', '', TextInput ],
  [ 'max_runtime', '', TextInput ],
  [ 'min_year', '', TextInput ],
  [ 'cast', [], MultiSelect ],
  [ 'director', [], MultiSelect ],
  [ 'dp', [], MultiSelect ],
  [ 'presentation_type', [], MultiSelect ],
  [ 'hide_watched', false, Checkbox ],
  [ 'show_bookmarked', false, Checkbox ],
  [ 'show_liked', false, Checkbox ],
];

export const PROVIDERS = {
  'amazonHboNow': '200',
};

export const sort = [
  'Newest',
  'Shortest TRT',
  'Alpha',
  'Rating',
  'Price',
  'Oldest',
  'Longest TRT',
];

export const RATING_SLUG_TEMPLATE_MAP = {
  imdb: 'ratingsImdb',
  tmdb: 'ratings',
};
