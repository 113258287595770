import { createSlice } from '@reduxjs/toolkit';
import { userFilms } from './userFilms.js';

export const results = createSlice({
  name: 'results',
  initialState: [],
  reducers: {
    set: (state, { payload }) => payload,
  },
  extraReducers: {
    [userFilms.actions.set]: (state, { payload = {} }) => {
      return state.map(result => ({
        ...result,
        user: payload[result.tmdb_id] || {},
      }));
    },
    [userFilms.actions.update]: (state, { payload }) => {
      const i = state.findIndex(r => r.tmdb_id === payload.tmdb_id);

      if (i > -1) state[i].user = payload;
      else return state;
    },
  }
})
