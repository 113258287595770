import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { userAutoList } from '../store/slices';

import Filters from '../components/Filters.jsx';
import Results from '../components/Results.jsx';
import Sort from '../components/Sort.jsx';

const SLUG_REGEX = /^\/user\/autolist\/([\w-]+)$/;

export default function ListView () {
  let { slug } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const d = s => dispatch(userAutoList.actions.set(s));

    d(slug);

    function handlePopState (e) {
      // dis ugly and random
      const listSlug = window.location.pathname.match(SLUG_REGEX)[1];
      d(listSlug);
    }

    window.addEventListener('popstate', handlePopState);

    return function cleanup () {
      window.removeEventListener('popstate', handlePopState);
    };
  });

  return (
    <div className="view view--list">
      <Filters />
      <Sort />
      <Results />
    </div>
  )
}
