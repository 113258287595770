import localforage from 'localforage';

import exists from '../../../shared/util/exists.js';
import omit from '../../../shared/util/omit.js';
import pick from '../../../shared/util/pick.js';

const KEY_PREFIX = 'watchlist';

const PERSIST_BLACKLIST = [
  'data',
  'filterCounts',
  'filterOptions',
  'movie',
  'movies',
  'results',
  'userAutoList',
  'userFilms',
  'userLists',
];

const RETRIEVE_FILTERS_WHITELIST = [
  'monetization_type',
  'presentation_type',
  'provider',
];

export function formatKey (key) {
  return `${KEY_PREFIX}.${key}`;
}

export function get (key, defaultVal = null) {
  return localforage.getItem(formatKey(key)).then(val => exists(val) ? val : defaultVal);
}

export function set (key, val) {
  return localforage.setItem(formatKey(key), val);
}

export function getPersistentState () {
  return get('storeState', {})
    .then(state => ({
      filters: pick(RETRIEVE_FILTERS_WHITELIST, state.filters),
      shortlist: state.shortlist,
    }))
    // return an empty object if anything went wrong
    .catch(e => ({}));
}

export function persistState (state) {
  return set('storeState', omit(PERSIST_BLACKLIST, state));
}
