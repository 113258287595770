import React from 'react';
import memo from 'memoize-one';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { userAutoList } from '../store/slices';
import { getShortlist, getUser, getUserFilms, getUserAutoList } from '../store/selectors.js';

const memoCounts = memo(({ shortlist, userFilms }) => {
  let counts = {
    bookmarked: 0,
    liked: 0,
    shortlisted: shortlist.length,
    watched: 0,
  };

  Object.entries(userFilms).forEach(([ , uf ]) => {
    if (uf.bookmarked) counts.bookmarked++;
    if (uf.liked) counts.liked++;
    if (uf.watched) counts.watched++;
  });

  return counts;
});

export default function UserListSelector () {
  const dispatch = useDispatch();
  const history = useHistory();
  const shortlist = useSelector(getShortlist);
  const user = useSelector(getUser, shallowEqual);
  const userFilms = useSelector(getUserFilms, shallowEqual);
  const userAutoListSelected = useSelector(getUserAutoList, shallowEqual);
  const counts = memoCounts({ shortlist, userFilms });

  const listOptions = React.useMemo(() => (
    user.username
      ? [
        'bookmarked',
        'liked',
        'shortlisted',
        'watched',
      ] : [ 'shortlisted' ]
  ), [ user ]);

  function handleChange (e) {
    const slug = e.target.value;

    if (slug === 'shortlisted') history.push(`/shortlist/`);
    else {
      dispatch(userAutoList.actions.set(slug));
      history.push(`/user/autolist/${slug}`);
    }
  }

  return (
    <select onChange={handleChange} value={userAutoListSelected}>
      <option value="" disabled>Select a watchlist</option>
      {listOptions.map(slug => (
        <option key={slug} value={slug}>{`View ${counts[slug]} ${slug} films`}</option>
      ))}
    </select>
  );
}
