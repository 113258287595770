import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import makeStore from './client/store';
import { init } from './client/store/actions.js';
import { getDefaultFilters } from './client/store/util.js'

import './client/index.css';
import App from './client/App';
import * as serviceWorker from './client/serviceWorker';

import { init as initShortlist } from './client/lib/shortlist.js';
import { getPersistentState } from './client/lib/storage';

window.app = window.app || {};

initShortlist()
  .then(getPersistentState)
  .then(({ filters, shortlist }) => makeStore({
    filters: {
      ...getDefaultFilters(),
      ...filters,
    },
    shortlist,
  }))
  .then(store => {
    window.app.store = store;

    // fetch and render
    store.dispatch(init())
      .then(() => {
        ReactDOM.render(
          <Provider store={store}>
            <App />
          </Provider>,
          document.getElementById('root')
        );
      });
  });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
