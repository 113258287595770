import streamMovieList from './util/streamMovieList.js';

function req (url, body, method = 'POST', headers = {}) {
  let init = {
    credentials: 'include',
    headers,
    method,
  };

  if (body) {
    init.body = JSON.stringify(body);
    Object.assign(init.headers, {
      'Content-Type': 'application/json',
    });
  }

  const request = new Request(url, init);

  return fetch(request)
    .then(res => {
      if (res.ok) return res.json();

      return res.json()
        .then(errors => Promise.reject(errors));
    });
}

export function fetchFilms () {
  return req('/api/user/film/', null, 'GET');
}

export function fetchLists () {
  return req('/api/user/list/', null, 'GET');
}

export function login ({ identifier, password }) {
  return req('/api/user/login', { identifier, password });
}

export function logout () {
  return req('/api/user/logout');
}

export function register ({ email, letterboxd_username, password, username }) {
  return req('/api/user/register', { email, letterboxd_username, password, username });
}

/**
 * @param  {string} list
 * @param  {string} locale
 * @return {RxJS.Observable}
 */
export function streamAutoList (list, locale) {
  return streamMovieList(`/api/user/autolist/${list}`, {
    credentials: 'include',
    headers: {
      'x-locale': locale,
    },
  });
}

/**
 * @param  {string} list
 * @param  {string} locale
 * @return {RxJS.Observable}
 */
export function streamList (list, locale) {
  return streamMovieList(`/api/user/list/${list}`, {
    credentials: 'include',
    headers: {
      'x-locale': locale,
    },
  });
}

export function updateFilm (film) {
  return film.id
    ? req(`/api/user/film/${film.id}`, film, 'PUT')
    : req(`/api/user/film/`, film, 'POST');
}
