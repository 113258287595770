import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getFilterValues } from '../store/selectors.js';
import { set } from '../store/lib/actionTypeGenerators.js';

export default function MultiSelect ({ name, options }) {
  const dispatch = useDispatch();
  const selectedValues = useSelector(state => getFilterValues(state, name), shallowEqual);

  function handleChange (e) {
    const data = Array.from(e.target.selectedOptions).map(e => e.value);

    dispatch({ data, type: set(name) });
  }

  return (
    <select multiple name={name} onChange={handleChange} value={selectedValues.map(String)}>
      {options.map(([ label, value, disabled ]) => (
        <option
          disabled={disabled}
          key={value}
          value={String(value)}>{label}</option>
      ))}
    </select>
  );
}
