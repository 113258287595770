import React from 'react';
import { Link } from "react-router-dom";

import MovieActions from './MovieActions.jsx';

import posterUrl from '../util/tmdbPosterUrl.js';

export default function Result ({ movie }) {
  return (
    <li className="movie--view_list results-list__item" key={movie.id}>
      <Link to={`/movie/${movie.id}`}>
        <img alt={`Movie poster for ${movie.title} (${movie.year})`} className="movie__poster" src={posterUrl(movie.poster)} />
      </Link>
      <MovieActions movie={movie} />
      <br />
    </li>
  )
}
