import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { getFilterCounts, getFilterOptions, getProviders } from '../store/selectors.js';
import { filters } from '../constants.js';

function getProviderLabel (id, providers) {
  return (providers.find(p => p.id === id) || {}).clear_name;
}

export default function Filters (props) {
  const filterOptions = useSelector(getFilterOptions, shallowEqual);
  const filterCounts = useSelector(getFilterCounts, shallowEqual);
  const providers = useSelector(getProviders, shallowEqual);

  return (
    <form className="filters">
      {filters.map(([ name, defaultValue, Component ]) => {
        let props = { name };
        const filterCount = name in filterCounts ? filterCounts[name] : {};

        if (name in filterOptions) {
          props.options = filterOptions[name].map(v => {
            const count = filterCount[v] || 0;
            const label = name === 'provider' ? getProviderLabel(v, providers) : v;

            return [
              `${label} (${count})`,
              v,
              // count === 0,
            ];
          });
        }

        if (!isNaN(filterCount)) props.count = filterCount;

        return <Component key={name} {...props} />;
      })}
    </form>
  )
}
