import { createSlice } from '@reduxjs/toolkit';

export const shortlist = createSlice({
  name: 'shortlist',
  initialState: [],
  reducers: {
    add: (state, { payload }) => state.concat(payload),
    remove: (state, { payload }) => state.filter(m => m.id !== payload.id),
    set: (state, { payload }) => payload,
  },
})
