import { filters } from '../constants.js';
import { set as setActionType } from './lib/actionTypeGenerators.js';
// import { set } from './lib/reducerGenerators.js';

export function getDefaultFilters () {
  return Object.fromEntries(filters);
}

export function getDefaultFilterOptions () {
  return Object.fromEntries(
    filters
      // filter out any filters that aren't multi-select (and thus have no options)
      .filter(([ , defaultValue ]) => Array.isArray(defaultValue))
  );
}

export function getFilterActionTypes () {
  return filters
  .reduce((list, [ key, defaultVal ]) => {
    // if (Array.isArray(defaultVal)) list.push(...listActionTypes(key));
    // else if (typeof defaultVal === 'string') list.push(setActionType(key));
    list.push(setActionType(key));

    return list;
  }, []);
}


// export function getFilterReducers () {
//   return Object.fromEntries(
//     filters.map(([ key, defaultValue ]) => [ key, set(setActionType(key), defaultValue) ])
//   );
// }
