// import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  locale as localeSlice,
  locales as localesSlice,
  lists as listsSlice,
  user,
} from './slices';

import { fetchLists, fetchLocales } from '../api';

import getLocale from '../lib/session/getLocale.js';
import getUsername from '../util/getUsername.js';

export const ACTION_TYPES = {
  SET_FILTERS: 'SET_FILTERS',
};

export function init () {
  return (dispatch, getState) => {
    const username = getUsername();

    if (username) dispatch(user.actions.set({ username }));

    return Promise.all([
      getLocale().then(locale => dispatch(localeSlice.actions.set(locale))),
      fetchLists().then(lists => dispatch(listsSlice.actions.set(lists))),
      fetchLocales().then(locales => dispatch(localesSlice.actions.set(locales))),
    ]);
  }
}

export function setFilters (data) {
  return { data, type: ACTION_TYPES.SET_FILTERS };
}
