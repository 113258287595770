import React from 'react';
import { useParams } from "react-router-dom";
import memo from 'memoize-one';
import { useDispatch, useSelector } from 'react-redux';

import MovieActions from './MovieActions.jsx';
import MovieOffers from './MovieOffers.jsx';

import { fetchLetterboxdSlug, fetchMovieDetails } from '../api';

import { movie as movieSlice } from '../store/slices';
import { getLocale, getMovie, getProviders, getResults } from '../store/selectors.js';
import { generateUrl } from '../lib/url';
import jwPageUrl from '../util/jwPageUrl.js';
import posterUrl from '../util/tmdbPosterUrl.js';

import '../styles/movie.css';

const memoResults = memo(results => {
  let resultsDictionary = {};

  results.forEach(r => resultsDictionary[r.id] = r);

  return resultsDictionary;
});

export default function Results (props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ letterboxdSlug, setLetterboxdSlug ] = React.useState(null);
  // const movies = useSelector(getMovies);
  const results = memoResults(useSelector(getResults));
  const locale = useSelector(getLocale);
  const movieInStore = useSelector(getMovie);
  const movie = movieInStore && movieInStore.id === id
    ? movieInStore
    : results[id];
  const providers = memoResults(useSelector(getProviders));
  React.useEffect(() => {
    fetchMovieDetails(id, locale).then(movie => {
      dispatch(movieSlice.actions.set(movie));
    });
  }, [ dispatch, id, locale ]);

  const { title, year } = (movie || {});
  React.useEffect(() => {
    if (!title || !year) return;

    // NOTE: using a stream here for cancellation
    const fetch$ = fetchLetterboxdSlug({ title, year }).subscribe(setLetterboxdSlug);

    return () => fetch$.unsubscribe();
  }, [ title, year ]);

  return !movie ? <div>Movie not found or still loading...</div> : (
    <div className="movie movie--view_details">
      <h3 className="movie__title">{`${movie.title} (${movie.year})`}</h3>
      <img alt={`Movie poster for ${movie.title} (${movie.year})`} className="movie__poster" src={posterUrl(movie.poster)} />
      <MovieActions movie={movie} />
      <div className="movie__links">
        <a href={jwPageUrl(movie.jwPath)}>View on JustWatch</a>
        <span>---</span>
        {letterboxdSlug && <React.Fragment>
          <a href={generateUrl('movieLetterboxd', { slug: letterboxdSlug })}>View on Letterboxd</a>
          <span>---</span>
        </React.Fragment>}
        <a href={generateUrl('movieImdb', { id: movie.imdb_id })}>View on IMDB</a>
        <span>---</span>
        <a href={generateUrl('movie', { id: movie.tmdb_id })}>View on TMDb</a>
      </div>
      <div className="movie__synopsis">{movie.synopsis}</div>
      <div className="movie__certification">{`Certification: ${movie.certification}`}</div>
      <div className="movie__genres">{`Genres: ${movie.genres.join(', ')}`}</div>
      <div className="movie__runtime">{`Runtime: ${movie.runtime}`}</div>
      <ul className="movie__credits">
        <li className="movie__credit">
          <span>Directed by:</span>
          {movie.directors.map(([ name, id ]) => (
            <a key={id} href={generateUrl('person', { id })}>{name}</a>
          ))}
        </li>
        <li className="movie__credit">
          <span>Written by:</span>
          {movie.writers.map(([ name, id ]) => (
            <a key={id} href={generateUrl('person', { id })}>{name}</a>
          ))}
        </li>
        <li className="movie__credit">
          <span>Cinematography by:</span>
          {movie.dps.map(([ name, id ]) => (
            <a key={id} href={generateUrl('person', { id })}>{name}</a>
          ))}
        </li>
        <li className="movie__credit">
          <span>Starring:</span>
          {movie.cast.map(([ name, id ]) => (
            <a key={id} href={generateUrl('person', { id })}>{name}</a>
          ))}
        </li>
      </ul>
      Ratings:
      <ul className="movie__ratings">
        <li className="movie__rating--average">{`average: ${movie.rating}`}</li>
        <li className={`movie__rating--tmdb`}>
          <a href={generateUrl('ratings', { id: movie.id })}>
            {`tmdb: ${movie.ratings.tmdb}`}
          </a>
        </li>
        <li className={`movie__rating--imdb`}>
          <a href={generateUrl('ratingsImdb', { id: movie.imdb_id })}>
            {`imdb: ${movie.ratings.imdb}`}
          </a>
        </li>
      </ul>
      Trailers:
      <ul className="movie__trailers">
        {movie.trailers.map(([ name, url ]) => <li key={url}><a href={url}>{name}</a></li>)}
      </ul>

      <MovieOffers movie={movie} providers={providers} />
    </div>
  )
}
