import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { sort } from '../constants.js';
import { sort as sortSlice } from '../store/slices';
import { getSort } from '../store/selectors.js';

export default function Sort () {
  const dispatch = useDispatch();
  const sortSelected = useSelector(getSort, shallowEqual);

  function handleChange (e) {
    dispatch(sortSlice.actions.set(e.target.value));
  }

  return (
    <select onChange={handleChange} value={sortSelected}>
      <option value="" disabled>Sort by...</option>
      {sort.map(label => (
        <option key={label} value={label}>{label}</option>
      ))}
    </select>
  );
}
