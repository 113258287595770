import { createSlice } from '@reduxjs/toolkit';

export const userFilms = createSlice({
  name: 'userFilms',
  initialState: {},
  reducers: {
    set: (state, { payload }) => payload.reduce((obj, uf) => ({
      ...obj,
      [uf.tmdb_id]: uf,
    }), {}),
    update: {
      prepare: (payload, meta = {}) => ({ meta, payload }),
      reducer (state, { payload }) {
        state[payload.tmdb_id] = payload;
      },
    }
  },
});
