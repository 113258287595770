import React from 'react';

import UserAutoListSelector from '../components/UserAutoListSelector.jsx';
import UserForm from '../containers/UserForm.jsx';
import UserListSelector from '../components/UserListSelector.jsx';

export default function HomeView () {
  return (<div className="home">
    <UserForm />
    <UserAutoListSelector />
    <UserListSelector />
  </div>);
}
