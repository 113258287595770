import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { list } from '../store/slices';
import { getListOptions, getListSelected } from '../store/selectors.js';

export default function ListSelector () {
  const dispatch = useDispatch();
  const history = useHistory();
  const listOptions = useSelector(getListOptions, shallowEqual);
  const listSelected = useSelector(getListSelected, shallowEqual);

  function handleChange (e) {
    const slug = e.target.value;
    dispatch(list.actions.set(slug));
    history.push(`/list/${slug}`);
  }

  return (
    <select onChange={handleChange} value={listSelected}>
      <option value="" disabled>Select a watchlist</option>
      {listOptions.map(list => (
        <option key={list.slug} value={list.slug}>{list.name}</option>
      ))}
    </select>
  );
}
