import React from 'react';
import { useSelector } from 'react-redux';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import Result from './Result.jsx';
import SubscriptionSuggestions from '../components/SubscriptionSuggestions.jsx';
import { Link } from 'react-router-dom';

import { getResults } from '../store/selectors.js';
import getRandomResult from '../util/getRandomResult.js';

import '../styles/results.css';

// NOTE: originally preferred storing in history state, but that triggers a re-render
// when history changes
let scrollLeft;

export default function Results () {
  const listRef = React.createRef();
  const results = useSelector(getResults);
  const randomResult = React.useMemo(() => getRandomResult(results), [ results ]);

  React.useEffect(() => {
    // restore scroll
    // console.log('restoring scroll left', scrollLeft, listRef.current.scrollWidth);
    listRef.current.scrollLeft = scrollLeft;

    // listen for scrolls and update state
    const scroll$ = fromEvent(listRef.current, 'scroll')
      .pipe(
        map(e => e.target.scrollLeft),
        debounceTime(120),
      )
      .subscribe(_scrollLeft => {
        scrollLeft = _scrollLeft;
      });

    return () => scroll$.unsubscribe();
  }, []); // eslint-disable-line 

  return (
    <div className="results">
      <div className="results__count">{`${results.length} films match your search`}</div>
      {randomResult && (
        <Link className="btn results__lucky-btn" to={`/movie/${randomResult.id}`}>
          I'm feeling lucky
        </Link>
      )}
      <ul ref={listRef} className="results results-list">
        {results.map(movie => <Result key={movie.id} movie={movie} />)}
      </ul>
      {window.location.pathname.startsWith('/user/autolist/bookmarked') && (
        <SubscriptionSuggestions />
      )}
    </div>
  )
}
